import styled from 'styled-components';

export const AppStyled = styled.div`
    z-index: 1;
    position: fixed;
    right: 0px;
    bottom: 0px;
    width: 95px;
    height: 98px;

    .AIEverywhereClient {
        position: absolute;
        right: 0px;
        transform: translateY(-100%);
        width: 400px;
        height: calc(100vh - 40px - 20px - 98px - 20px);
        background-color: white;
        box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(255, 255, 255, 1);
        border-radius: 10px;
        z-index: 1000;
        box-sizing: content-box;
        font-size: 16px;
        border-radius: 16px;
    }
    @media (min-width: 500px) {
        .AIEverywhereClient {
            top: -20px;
        }
    }
    @media (max-width: 500px) {
        .AIEverywhereClient {
            position: fixed;
            bottom: -100vh;
            left: 0;
            right: 0;
            transform: translateY(-100%);
            width: 100vw;
            height: 100vh;
        }
    }

    .App-logo {
        height: 40vmin;
        pointer-events: none;
    }

    @media (prefers-reduced-motion: no-preference) {
        .App-logo {
            animation: App-logo-spin infinite 20s linear;
        }
    }

    .App-header {
        background-color: #282c34;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: calc(10px + 2vmin);
        color: white;
    }

    .App-link {
        color: #61dafb;
    }

    @keyframes App-logo-spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;
