import { FunctionComponent, ReactNode, useState } from 'react';
import { ShowContextProvider } from './ShowChatWindowContext';
import {ChatMessageContextProvider} from "./ChatMessageContext";
import {QueryClientProvider} from "@tanstack/react-query";
import {queryClient} from "../client/Query";
import { IdentifiersContextProvider } from './Identifiers.context';
import { ActionContextProvider } from './action/ActionContext';

interface ContextProviderComponentProps {
    children: ReactNode;
}
export const ContextProviderComponent: FunctionComponent<ContextProviderComponentProps> = (props) => {
    return (
        <QueryClientProvider client={queryClient}>
        <IdentifiersContextProvider>
        <ActionContextProvider>
        <ShowContextProvider>
            <ChatMessageContextProvider>
                {props.children}
            </ChatMessageContextProvider>
        </ShowContextProvider>
        </ActionContextProvider>
        </IdentifiersContextProvider>
        </QueryClientProvider>
    );
};
