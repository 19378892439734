import Cookies from 'js-cookie';

export class CookieStorage {
    public save(key: string, value: string, expires: number = 365): string {
        Cookies.set(key, value, { expires: expires, sameSite: 'None', secure: true })

        return value;
    }

    public get(key: string, defaultValue: string): string {
        const cookieValue = Cookies.get(key);

        if(!cookieValue) {
            return defaultValue;
        }

        return cookieValue;
    }
}