import React, { useCallback, JSX, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { SenderType } from '../../../model/message/SenderType';
import { BottomInfoStyled, BottomStyled, ChatInputTextarea, InputBoxStyled, SendBoxStyled } from './Styled';
import { Form } from 'react-bootstrap';
// @ts-ignore
import { ReactComponent as SendIcon } from '../../../assets/send-btn.svg';
// @ts-ignore
import { ReactComponent as AttachIcon } from '../../../assets/attach-btn.svg';
import { useChat } from '../../../hooks/useChat';
import { Message } from '../../../model/message/Message';

const INPUT_MESSAGE_MAX_LENGTH = 1000;

export function ChatInputComponent(): JSX.Element {
    const [message, setMessage] = useState<string>('');
    const { sendMessage, waitingForResponse } = useChat();
    const [isComposing, setIsComposing] = useState(false);

    const submitMessage = useCallback(async (messageBody: string) => {
        const userMessage: Message = {
            id: uuidv4(),
            senderType: SenderType.User,
            messageBody,
        };
        setMessage('');
        try {
            await sendMessage(userMessage);
        } catch (error) {
            console.error(error);
        }
    }, []);

    const handleCompositionStart = () => {
        setIsComposing(true);
    };

    // @ts-ignore
    const handleCompositionEnd = (e) => {
        setIsComposing(false);
        setMessage(e.target.value);
    };

    return (
        <div className="AIEveywhereChatInput">
            <Form>
                <InputBoxStyled>
                    <ChatInputTextarea style={{ height: '65%' }}>
                        <Form.Control
                            className="textarea"
                            as="textarea"
                            aria-label="With textarea"
                            placeholder="Enter your message..."
                            value={message}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)}
                            onKeyDown={(e) => {
                                if (waitingForResponse) {
                                    e.preventDefault();
                                    return;
                                }
                                if (e.key === 'Enter' && !e.shiftKey && !isComposing) {
                                    e.preventDefault();
                                    if (message.trim() === '') {
                                        setMessage('');
                                        return;
                                    }
                                    submitMessage(message);
                                }
                            }}
                            onCompositionStart={handleCompositionStart}
                            onCompositionEnd={handleCompositionEnd}
                            maxLength={INPUT_MESSAGE_MAX_LENGTH}
                        />
                    </ChatInputTextarea>
                    <SendBoxStyled>
                        <AttachIcon style={{ visibility: 'hidden' }}></AttachIcon>
                        <div style={{ display: 'flex' }}>
                            <p style={{ marginRight: '10px' }}>{`${message.length}/${INPUT_MESSAGE_MAX_LENGTH}`}</p>
                            <SendIcon
                                className={`${message.length <= 0 ? 'disable' : 'send-icon'}`}
                                onClick={() => {
                                    if (message.length > 0) {
                                        submitMessage(message);
                                    }
                                }}
                            ></SendIcon>
                        </div>
                    </SendBoxStyled>
                </InputBoxStyled>
                <BottomStyled>
                    <BottomInfoStyled className="bottom-info">Powered by Garam AI</BottomInfoStyled>
                </BottomStyled>
            </Form>
        </div>
    );
}
