import React, { useContext, JSX } from 'react';
import { Modal } from 'react-bootstrap';
import { ShowChatWindowContext } from '../../../context/ShowChatWindowContext';
// @ts-ignore
import { ReactComponent as MoreBtnIcon } from '../../../assets/more-btn.svg';
// @ts-ignore
import { ReactComponent as CloseBtnIcon } from '../../../assets/close-btn.svg';
import { ChatRoomHeaderStyled } from './Styled';
import { useChatRoomHeader } from './useChatRoomHeader';

export function ChatRoomHeaderComponent(): JSX.Element {
    const { toggleChatWindow } = useContext(ShowChatWindowContext);
    const { getCloseButtonVisibility } = useChatRoomHeader();

    return (
        <ChatRoomHeaderStyled >
            <Modal.Header className="chatHeader">
                <Modal.Title></Modal.Title>
                <div style={{ display: 'flex' }}>
                    <MoreBtnIcon style={{ margin: '0px 10px', visibility: 'hidden' }} onClick={() => {}} />
                    <CloseBtnIcon className="close-icon" style={{ visibility: getCloseButtonVisibility()}} onClick={() => toggleChatWindow()} />
                </div>
            </Modal.Header>
        </ChatRoomHeaderStyled>
    );
}
