import {FacadeServiceClient} from "./FacadeServiceClient";
import {ApplicationDetail} from "./model/ApplicationDetail";
import {CustomerInfo} from "../../model/CustomerInfo";
import { Service } from '../../model/Service';
import { User } from "src/model/User";

export class GaramFacadeServiceClient implements FacadeServiceClient {
    private readonly hostUrl: string;

    constructor(hostUrl: string) {
        this.hostUrl = hostUrl;
    }

    public async upsertCustomer(serviceId: string, customer: CustomerInfo): Promise<void> {
        (await fetch(`${this.hostUrl}/customer?customerId=${customer.id}&serviceId=${serviceId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                activities: customer.activities? customer.activities : new Map(),
                customerContact: customer.customerContact,
                contactType: customer.contactType,
                metadata: customer.metadata,
            }),
        }))
    }

    public async getCustomer(serviceId: string, customerId: string): Promise<CustomerInfo | undefined> {
        const customerInfoInText = await (await fetch(`${this.hostUrl}/customer/${customerId}?serviceId=${serviceId}`, {
            method: 'GET',
        })).text();

        if(!customerInfoInText) {
            return undefined;
        }

        return  JSON.parse(customerInfoInText);
    }

    public async createConversation(serviceId: string, applicationId: string, customerId: string): Promise<string> {
        return (await fetch(`${this.hostUrl}/chat/conversation`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                serviceId: serviceId,
                applicationId: applicationId,
                customerId: customerId,
            }),
        })).text()
    }

    public async getServiceByServiceId(serviceId:string): Promise<Service> {
        return (await fetch(`${this.hostUrl}/service/${serviceId}`, {
            method: 'GET',
        })).json()
    }

    public async getGuestToken(chatSessionId: string): Promise<string> {
        return (await fetch(`${this.hostUrl}/chat/guestToken?guestId=${chatSessionId}`, {
            method: 'GET',
        })).text()
    }

    public async getApplication(serviceId: string): Promise<ApplicationDetail> {
        return (
            await (
                await fetch(`${this.hostUrl}/applications?serviceId=${serviceId}`, {
                    method: 'GET',
                })
            ).json()
        )[0];
    }

    public async getUsersByServiceId(serviceId: string): Promise<User[]> {
        return (await fetch(`${this.hostUrl}/services/${serviceId}/users`)).json()
    }
}