import {Message} from "src/model/message/Message";
import {QueryMessage} from "src/model/QueryMessage";
import {ChatClient} from "./ChatClient";
import {v4 as uuidv4} from 'uuid';
import {MessageOwnerType} from "../api/model/MessageOwnerType";
import {SenderType} from "../../model/message/SenderType";

export interface ChatMessageRequest {
    id: string;
    type: MessageOwnerType;
    message: string;
}

export class PlaygroundChatClient implements ChatClient {
    private messages: Message[] = [];
    private listener: ((message: Message) => void)[] = [];
    private waitingForResponseListener: ((waitingForResponse: boolean) => void)[] = [];

    constructor(private readonly apiEndpoint: string) {
        this.addMessageListener('PlaygroundConversationId', (message) => {
            if(this.messages.length === 0 || this.messages[this.messages.length -1].id !== message.id) {
                this.messages.push(message)
            }
        })
    }

    public connect(customerId: string, token: string): Promise<string> {
        return Promise.resolve(customerId);
    }

    public getMessages(conversationId: string): Promise<Message[]> {
        return Promise.resolve([...this.messages.map(msg => ({...msg}))]);
    }

    public async sendMessage(conversationId: string, message: QueryMessage): Promise<void> {
        this.listener.forEach(listener => listener(message.userMessageBody));
        this.waitingForResponseListener.forEach(listener => listener(true));

        const responseMessage: Message = await this.queryChatBot(message.conversationId, message);

        this.waitingForResponseListener.forEach(listener => listener(false));

        this.listener.forEach(listener => listener(responseMessage));
    }

    public async addMessageListener(conversationId: string, callback: (message: Message) => void): Promise<void> {
        this.listener.push((message) => callback({...message}));
    }

    public async addWaitingForResponseListener(conversationId: string, callback: (waitingForResponse: boolean) => void): Promise<void> {
        this.waitingForResponseListener.push((waitingForResponse) => callback(waitingForResponse));
    }

    private convertToChatMessage(message: Message): ChatMessageRequest {
        return {
            id: message.id,
            type: message.senderType === 'User' ? MessageOwnerType.Customer : MessageOwnerType.Application,
            message: message.messageBody,
        }
    }

    private async queryChatBot(conversationId: string, message: QueryMessage): Promise<Message> {
        let httpResponse = await fetch(`${this.apiEndpoint}/service/${message.serviceId}/applications/${message.applicationId}/queryLLM`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                conversationId: conversationId,
                userMessageBody: {
                    id: uuidv4(),
                    type: `Customer`,
                    message: message.userMessageBody.messageBody,
                },
                chatHistory: this.messages.slice(0, this.messages.length -1).map(message => this.convertToChatMessage(message))
            }),
        });
        const response = await httpResponse.json();

        if (!httpResponse.ok) {
            return {
                id: uuidv4(),
                senderType: SenderType.Machine,
                messageBody: 'This is a temporary server error. \nPlease try again later.',
            };
        }

        return {
            id: response.id,
            senderType: SenderType.Machine,
            messageBody: response.responseBody,
        };
    }
}