import styled from 'styled-components';

export const MessageListStyled = styled.div`
    overflow-y: scroll;
    height: calc(100% - 200px + 32px);
`;

export const SystemMessageItem = styled.div`
    padding: 10px 14px;
    display: flex;
    align-items: flex-start;
    gap: 10px;
`;

export const YouMessageItem = styled.div`
    padding: 10px 14px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 10px;
`;

export const SystemMessageText = styled.div`
    width: auto;
    min-width: 170px;
    max-width: 70%;
    padding: 5px 20px 5px 20px;
    border-radius: 24px;
    background: linear-gradient(146.18deg, #7548d5 -281.14%, #83d5fb 375.45%);
    box-shadow: 0px 4px 20px 0px rgba(92, 128, 255, 0.1);

    font-size: 15px;
    font-weight: bold;
    line-height: 25px;
    text-align: left;
    color: rgb(250, 250, 250);
`;

export const YouMessageText = styled.div`
    width: auto;
    min-width: 170px;
    max-width: 70%;
    padding: 5px 20px 5px 20px;
    border-radius: 20px;

    font-size: 15px;
    font-weight: bold;
    line-height: 25px;
    text-align: left;
    color: rgba(100, 100, 100, 1);

    border: 1px solid rgba(239, 239, 239, 1);
    box-shadow: 0px 4px 20px 0px rgba(92, 128, 255, 0.1);
`;
