import { useMessageBubbleAction } from './useMessageBubbleAction';
import { MessageBubbleActionStyled } from './Styled';
import { useState } from 'react';
import { ActionType } from '../../model/ActionTypes';
import { useShowChatWindowContext } from '../../context/ShowChatWindowContext';

export const MessageBubbleActionComponent = () => {
    const [messageShownBefore, setMessageShownBefore] = useState(false);
    const { message, isMobile, randomPickUser, serviceName } = useMessageBubbleAction();
    const { toggleChatWindow } = useShowChatWindowContext();

    if(!messageShownBefore && message.actionType === ActionType.Message) {
        setMessageShownBefore(true);
    }

    if(!messageShownBefore) {
        return null;
    }

    return (
        <MessageBubbleActionStyled $isOpen={message.actionType === ActionType.Message} $isMobile={isMobile}>
            <div id='SpeechBubble' onClick={() => toggleChatWindow()}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {randomPickUser && randomPickUser.profileImageUrl && <img src={randomPickUser.profileImageUrl} className="userProfileImage mb-1 mx-2" />}
                    {randomPickUser && serviceName && <span className="userIntroduction">{randomPickUser.givenName} from {serviceName}</span>}
                </div>
                {message.actionType === ActionType.Message ? message.payload : ''}
            </div>
        </MessageBubbleActionStyled>
    )
}