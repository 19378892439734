import { AnalyticsClient } from './AnalyticsClient';
import ReactGA from 'react-ga';
import { ApplicationConfig } from '../../config/ApplicationConfig';
import { EventFactory } from './EventFactory';

export class GoogleAnalyticsClient implements AnalyticsClient {
    private readonly applicationId: string;
    private readonly serviceId: string;
    private readonly eventFactory: EventFactory;

    constructor(serviceId: string, applicationId: string, eventFactory: EventFactory) {
        ReactGA.initialize(ApplicationConfig.googleAnalysisTrackingId);
        this.applicationId = applicationId;
        this.serviceId = serviceId;
        this.eventFactory = eventFactory;
    }

    public sendCustomerClickedRecommendedLinkEvent(recommendedLink: string): void {
        const clickEvent = this.eventFactory.createCustomerClickRecommendedLinkEvent(recommendedLink);

        ReactGA.event({
            category: clickEvent.category,
            action: clickEvent.action,
            label: clickEvent.clickHref,
            dimension1: this.serviceId,
            dimension2: this.applicationId,
        });
    }

    public sendCustomerStartsConversation(location: string): void {
        ReactGA.event({
            category: 'Customer',
            action: 'StartsConversation',
            label: location,
            dimension1: this.serviceId,
            dimension2: this.applicationId,
        });
    }

    public sendCustomerOpenedChatBot(pathName: string): void {
        ReactGA.event({
            category: 'Customer',
            action: 'OpenedChatBot',
            label: pathName,
            dimension1: this.serviceId,
            dimension2: this.applicationId,
        });
    }
}
