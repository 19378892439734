import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'react-chat-elements/dist/main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ContextProviderComponent } from './context/ContextProvider.component';
import { ThemeProvider } from 'react-bootstrap';

function generateDivIfNotExist() {
    let element = document.getElementById('ai-everywhere-client');
    if (!element) {
        element = document.createElement('div');
        element.id = 'ai-everywhere-client';
        document.body.appendChild(element);
    }
}

generateDivIfNotExist();
const root = ReactDOM.createRoot(document.getElementById('ai-everywhere-client') as HTMLElement);
root.render(
    <ContextProviderComponent>
        <ThemeProvider>
            <App />
        </ThemeProvider>
    </ContextProviderComponent>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
