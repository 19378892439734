import { createContext, ReactNode } from 'react';
import { useIdentifier } from '../hooks/useIdentifier';
import { useQuery } from '@tanstack/react-query';


export interface IdentifiersContextType {
    serviceId: string;
    customerId: string;
    applicationId: string;
    isReady: boolean;
    isError: boolean;
}

const DEFAULT_IDENTIFIERS: IdentifiersContextType = {
    serviceId: '',
    customerId: '',
    applicationId: '',
    isReady: true,
    isError: false,
};

export const IdentifiersContext = createContext<IdentifiersContextType>(DEFAULT_IDENTIFIERS);

export function IdentifiersContextProvider({ children }: { children: ReactNode }) {
    const { loadIdentifiers } = useIdentifier();

    const {data: identifiersData = DEFAULT_IDENTIFIERS, isLoading, isError} = useQuery({
        queryKey: ['identifiers'],
        queryFn: loadIdentifiers
    });

    return (
        <IdentifiersContext.Provider value={{...identifiersData, isReady: !isLoading && !isError, isError: isError}}>
            { children }
        </IdentifiersContext.Provider>
    )
}

