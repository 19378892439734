import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Action, ActionType } from '../../model/ActionTypes';
import { ClientFactory } from '../../client/ClientFactory';

export interface ActionContextType {
    action: Action;
    reset: () => void;
}

const NoneAction: Action = {
    actionType: ActionType.None,
    payload: {},
}

const ActionContext = createContext<ActionContextType>({
    action: NoneAction,
    reset: () => {},
});

export function ActionContextProvider({ children }: { children: ReactNode }) {
    const [action, setAction] = useState<Action>(NoneAction);

    useEffect(() => {
        ClientFactory.createActionMessageClient().addActionMessageListener('ActionContextProvider', (action) => {
            setAction(action);
        });
    }, []);

    function reset() {
        setAction(NoneAction);
    }

    return (
        <ActionContext.Provider value={{action, reset}}>
            { children }
        </ActionContext.Provider>
    )
}

export function useActionContext(): ActionContextType {
    return useContext(ActionContext);
}