import {CustomerInfo, ServiceId} from "../model/CustomerInfo";
import {ClientFactory} from "../client/ClientFactory";
import { useContext, useState } from 'react';
import { IdentifiersContext } from '../context/Identifiers.context';
import { CustomerContactType } from '../model/CustomerContactType';

export function useCustomer(serviceId: ServiceId) {
    const { customerId } = useContext(IdentifiersContext);
    const facadeServiceClient = ClientFactory.createFacadeServiceClient();
    const [customerInfo, setCustomerInfo] = useState<CustomerInfo | undefined>(undefined);

    const loadCustomerInfo = async (): Promise<CustomerInfo> => {
        let loadedCustomerInfo = customerInfo;
        if (!loadedCustomerInfo) {
            loadedCustomerInfo = await facadeServiceClient.getCustomer(serviceId, customerId);
            if (!loadedCustomerInfo) {
                loadedCustomerInfo = createDefaultCustomerInfo(customerId)
                await facadeServiceClient.upsertCustomer(serviceId, loadedCustomerInfo);
            }
            setCustomerInfo(loadedCustomerInfo);
        }

        return loadedCustomerInfo!;
    }

    function createDefaultCustomerInfo(customerId: string): CustomerInfo {
        return {
            id: customerId,
            metadata: new Map(),
            customerContact: customerId,
            activities: new Map(),
            contactType: CustomerContactType.NONE,
        }
    }

    const updateCustomerInfo = async (customerInfo: CustomerInfo) => {
        await facadeServiceClient.upsertCustomer(serviceId, customerInfo);
    }

    return {
        loadCustomerInfo,
        updateCustomerInfo
    }
}