import { AnalyticsClient } from './AnalyticsClient';

export class DummyAnalyticsClient implements AnalyticsClient {
    public sendCustomerClickedRecommendedLinkEvent(recommendedLink: string): void {
        return;
    }

    public sendCustomerStartsConversation(location: string): void {
        return;
    }

    public sendCustomerOpenedChatBot(pathName: string): void {
        return;
    }
}
