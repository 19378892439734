import React, { ReactNode } from 'react';
import { Message } from '../../../model/message/Message';
import { MessageType } from 'react-chat-elements';
import { SenderType } from '../../../model/message/SenderType';
import { v4 as uuidv4 } from 'uuid';
import Markdown from 'react-markdown';

export class MessageTypeFactory {
    private static readonly MESSAGE_DEFAULT_VALUE = {
        focus: false,
        date: new Date(),
        titleColor: '#618EAB',
        forwarded: false,
        replyButton: false,
        removeButton: false,
        status: 'read' as any,
        notch: false,
        retracted: false,
    };

    private readonly applicationName: string;

    constructor(applicationName: string) {
        this.applicationName = applicationName;
    }

    public createMessage(message: Message): MessageType {
        return {
            ...MessageTypeFactory.MESSAGE_DEFAULT_VALUE,
            type: 'text',
            id: message.id,
            position: message.senderType === SenderType.Machine ? 'left' : 'right',
            title: message.senderType === SenderType.Machine ? this.applicationName : 'You',
            text: this.createMessageBody(message) as any,
        };
    }

    public createSystemMessage(systemMessageBody: string): MessageType {
        return {
            ...MessageTypeFactory.MESSAGE_DEFAULT_VALUE,
            type: 'system',
            id: uuidv4(),
            position: 'center',
            title: 'System',
            text: systemMessageBody,
        };
    }

    private createMessageBody(message: Message): ReactNode {
        return message.messageBody.split('\n').map((text, index) => (
            <Markdown key={index} className='chatMessage' components={{
                a: ({ node, children, ...props }) => (
                    <a {...props} target="_blank" rel="noopener noreferrer">
                        {children}
                    </a>
                ),
            }}>
                {text}
            </Markdown>
        ));
    }
}
