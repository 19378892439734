import { MessageListComponent } from './MessageList/MessageList.component';
import { ChatInputComponent } from './ChatInput/ChatInput.component';
import { ChatRoomHeaderComponent } from './ChatRoomHeader/ChatRoomHeader.component';
import { ChatRoomStyled } from './Styled';
import React, { JSX } from 'react';

export function ChatRoomComponent(): JSX.Element {
    return (
        <ChatRoomStyled>
            <ChatRoomHeaderComponent />
            <MessageListComponent />
            <ChatInputComponent />
        </ChatRoomStyled>
    );
}
