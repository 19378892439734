import { useChat } from '../../../hooks/useChat';
import { useContext } from 'react';
import { IdentifiersContext } from '../../../context/Identifiers.context';
import { ClientFactory } from '../../../client/ClientFactory';
import { MessageType } from 'react-chat-elements';
import { MessageTypeFactory } from './MessageTypeFactory';
import { useQuery } from '@tanstack/react-query';

export function useMessageListComponent() {
    const facadeServiceClient = ClientFactory.createFacadeServiceClient();
    const {messages, waitingForResponse} = useChat();
    const {serviceId} = useContext(IdentifiersContext);

    async function getApplicationName(): Promise<string> {
        return (await facadeServiceClient.getApplication(serviceId)).name;
    }

    const { data: applicationName = '' } = useQuery({
        queryKey: ['applicationName', serviceId],
        queryFn: getApplicationName
    });

    async function loadMessageDataSources(): Promise<MessageType[]> {
        const messageTypeFactory = new MessageTypeFactory(applicationName);

        return messages.map((message) => messageTypeFactory.createMessage(message));
    }

    const {data: messageDataSources = []} = useQuery({
        queryKey: ['messageDataSources', messages],
        queryFn: loadMessageDataSources
    });

    return { messageDataSources, waitingForResponse }
}