import { ClickEvent } from './model/Events';

export class EventFactory {
    public createCustomerClickRecommendedLinkEvent(recommendedLink: string): ClickEvent {
        return {
            category: `Customer`,
            action: `Click`,
            clickHref: recommendedLink,
        };
    }
}
