import styled from 'styled-components';

export const ChatRoomTitleStyled = styled.div`
    border-bottom: 1px solid rgba(233, 233, 233, 1);
    width: Fill (460px) px;
    height: Hug (112px) px;
    padding: 13px 0px 13px 0px;
    gap: 20px;
    opacity: 0px;

    .title {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: -0.05em;
        text-align: left;
        color: rgba(119, 133, 255, 1);
        text-align: center;
    }

    .welcome-message {
        font-size: 17px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.04em;
        text-align: left;
        color: rgba(98, 98, 98, 1);
        text-align: center;
    }
`;
