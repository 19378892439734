import { AppParams } from '../../model/AppParams';
import { AppMode } from '../../model/AppMode';

const SERVICE_ID_PARAM = 'serviceId';
const APP_MODE = 'mode';
const INITIAL_SHOW = 'show';
const DISABLE_CLOSE_BTN = 'disableClose';

export class URLParamAppParameterReader {
    public retrieveAppParams(): AppParams {
        const urlParams = new URLSearchParams(window.location.search);
        const serviceId = urlParams.get(SERVICE_ID_PARAM);
        const appMode = urlParams.get(APP_MODE);
        const show = urlParams.get(INITIAL_SHOW);

        return {
            appMode: appMode === 'playground' ? AppMode.Playground : AppMode.StandAlone,
            serviceId: serviceId!,
            initialShow: show === 'true',
            disableCloseButton: urlParams.get(DISABLE_CLOSE_BTN) === 'true',
        }
    }
}
