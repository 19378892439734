import { JSX } from 'react';
import { ChatRoomTitleStyled } from './Styled';
import { useChatRoomTitle } from './useChatRoomTitle';
import React from 'react';

export function ChatRoomTitle(): JSX.Element {
    const { title, greetingMessage } = useChatRoomTitle();

    return (
        <ChatRoomTitleStyled>
            <div className="title">{title}</div>
            <div className="welcome-message mt-2">{greetingMessage}</div>
        </ChatRoomTitleStyled>
    );
}
