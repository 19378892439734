import {ChatClient} from "./ChatClient";
import {StreamChat} from "stream-chat";
import {Message} from "../../model/message/Message";
import {SenderType} from "../../model/message/SenderType";
import {QueryMessage} from "../../model/QueryMessage";

export class StreamChatClient implements ChatClient {
    private static readonly CHANNEL_TYPE = 'messaging';

    constructor(private readonly chatClient: StreamChat) {}

    public async connect(customerId: string, token: string): Promise<string> {
        if (this.chatClient.user) {
            return customerId;
        }

        const user = await this.chatClient.connectUser({
            id: customerId,
            name: 'guest',
            role: 'user',
        }, token);

        return user?.me?.id!;
    }

    public async getMessages(conversationId: string): Promise<Message[]> {
        const conversation = this.chatClient.channel(StreamChatClient.CHANNEL_TYPE, conversationId);
        const messages = await conversation.query({ messages: { limit: 100 } });

        return messages.messages.map((message) => ({
            id: message.id,
            senderType: message.user?.role === 'user' ? SenderType.User : SenderType.Machine,
            messageBody: message.text!,
        }));
    }

    public async sendMessage(chatSessionId: string, message: QueryMessage): Promise<void> {
        await this.chatClient.channel(StreamChatClient.CHANNEL_TYPE, message.conversationId).sendMessage({
            text: message.userMessageBody.messageBody,
            user: { id: chatSessionId },
            service_id: message.serviceId,
        })
    }

    public async addMessageListener(conversationId: string, callback: (message: Message) => void): Promise<void> {
        const conversation = this.chatClient.channel(StreamChatClient.CHANNEL_TYPE, conversationId);
        await conversation.watch();

        conversation.on('message.new', (event) => {
            callback({
                messageBody: event.message?.text!,
                id: event.message?.id!,
                senderType: event.message?.user?.role === 'user' ? SenderType.User : SenderType.Machine,
            });
        })
    }

    public async addWaitingForResponseListener(conversationId: string, callback: (waitingForResponse: boolean) => void): Promise<void> {
        const conversation = this.chatClient.channel(StreamChatClient.CHANNEL_TYPE, conversationId);
        await conversation.watch();
        conversation.on('typing.start', () => {
            callback(true);
        })
        conversation.on('typing.stop', () => {
            callback(false);
        })
    }
}