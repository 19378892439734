import React, { useEffect, JSX } from 'react';
import { MessageListStyled, SystemMessageItem, SystemMessageText, YouMessageItem, YouMessageText } from './Styled';
import PulseLoader from 'react-spinners/PulseLoader';
import { ChatRoomTitle } from '../Title/ChatRoomTitle';
import { useMessageListComponent } from './useMessageListComponent';

export function MessageListComponent(): JSX.Element {
    const { messageDataSources, waitingForResponse } = useMessageListComponent();

    const scrollMessageListToBottom = () => {
        const mlistElement = document.getElementById('message-list');
        if (mlistElement && typeof mlistElement !== 'undefined') {
            mlistElement.scrollTop = mlistElement.scrollHeight;
        }
    };

    useEffect(() => {
        scrollMessageListToBottom();
    }, [messageDataSources, waitingForResponse]);

    return (
        <MessageListStyled id="message-list">
            <ChatRoomTitle />
            {Array.from(messageDataSources).map((item, index) => {
                if (item.title === 'You') {
                    return (
                        <YouMessageItem key={item.id}>
                            <YouMessageText>{item.text}</YouMessageText>
                        </YouMessageItem>
                    );
                } else {
                    return (
                        <SystemMessageItem key={item.id}>
                            <SystemMessageText>
                                {waitingForResponse && messageDataSources.length === index + 1 ? <PulseLoader size={5} margin={5} color="#9a2dfa" /> : item.text}
                            </SystemMessageText>
                        </SystemMessageItem>
                    );
                }
            })}
            {waitingForResponse && (
                <SystemMessageItem>
                    <SystemMessageText style={{ display: 'flex', height: 30, justifyContent: 'center', alignItems: 'center' }}>
                        <PulseLoader size={5} color="#9a2dfa" />
                    </SystemMessageText>
                </SystemMessageItem>
            )}
        </MessageListStyled>
    );
}
