import { useActionContext } from '../../context/action/ActionContext';
import { ActionType, MessageActionType } from '../../model/ActionTypes';
import { DeviceAnalyzer } from '../../client/device/DeviceAnalyzer';
import { useContext, useEffect, useRef } from 'react';
import { useShowChatWindowContext } from '../../context/ShowChatWindowContext';
import { User } from '../../model/User';
import { IdentifiersContext } from '../../context/Identifiers.context';
import { ClientFactory } from '../../client/ClientFactory';
import { useQuery } from '@tanstack/react-query';
import { Service } from '../../model/Service';

export function useMessageBubbleAction() {
    const {action, reset} = useActionContext();
    const { resetChatWindow } = useShowChatWindowContext();
    const timeoutRef = useRef<number | null>(null);
    const { serviceId } = useContext(IdentifiersContext);
    const facadeClient = ClientFactory.createFacadeServiceClient();

    function getUsers(): Promise<User[]> {
        return facadeClient.getUsersByServiceId(serviceId);
    }

    function getService(): Promise<Service> {
        return facadeClient.getServiceByServiceId(serviceId);
    }

    const { data: users } = useQuery({
        queryKey: ['users', serviceId],
        queryFn: getUsers,
    });

    const { data: serviceName } = useQuery({
        queryKey: ['service', serviceId],
        queryFn: getService,
        select: (data) => {
            if (data) {
                return data.name;
            }
        }
    });


    useEffect(() => {
        if (action.actionType === ActionType.Message) {
            const timeOutMessage = action.payload as string;

            if (timeoutRef.current !== null) {
                clearTimeout(timeoutRef.current);
            }

            timeoutRef.current = window.setTimeout(() => {
                if (timeOutMessage === action.payload) {
                    reset();
                    setTimeout(() => {
                        resetChatWindow();
                    }, 100);
                }
            }, 5_000);
        }
    }, [action]);

    const isMobile = new DeviceAnalyzer().isMobile();

    return { message: action as MessageActionType, isMobile, randomPickUser: users ? users[0] : undefined, serviceName };
}