import { ClientFactory } from '../../../client/ClientFactory';

export function useChatRoomHeader() {
    function getCloseButtonVisibility(): string {
        const disableCloseButton = ClientFactory.createURLParameterReader().retrieveAppParams().disableCloseButton;

        if(disableCloseButton) {
            return 'hidden';
        }

        return 'visible';
    }

    return { getCloseButtonVisibility };
}