import { Action } from '../../model/ActionTypes';

export type ActionListenerId = string;

export class ActionMessageClient {
    private static actionListenerIds: Set<ActionListenerId> = new Set<ActionListenerId>();

    public sendActionMessageToAgent(action: Action): void {
        window.parent.postMessage(action, '*');
    }

    public addActionMessageListener(actionListenerId: ActionListenerId, listenerFunction: (action: Action) => void ): void {
        if (!ActionMessageClient.actionListenerIds.has(actionListenerId)) {
            window.addEventListener('message', (event: MessageEvent<Action>) => {
                if(this.isActionMessage(event)) {
                    listenerFunction(event.data);
                }
            });

            this.removeEventListenerWhenUnloaded('message', listenerFunction);

            ActionMessageClient.actionListenerIds.add(actionListenerId);
        }
    }

    private removeEventListenerWhenUnloaded(type: string, eventListener: any): void {
        window.addEventListener('unload', () => {
            window.removeEventListener(type, eventListener);
        });
    }

    private isActionMessage(event: MessageEvent<Action>): boolean {
        return !!event.data && !!event.data.actionType;
    }
}