import styled from 'styled-components';
import { DeviceAnalyzer } from '../../client/device/DeviceAnalyzer';

const deviceAnalyzer = new DeviceAnalyzer();

export const ChatButtonStyled = styled.div<{ $isOpen?: boolean }>`
    .chatbotButton {
        border-radius: 50%; /* Makes the button round */
        width: ${deviceAnalyzer.isMobile() ? '70px' : '80px'}; /* Width of the button */
        height: ${deviceAnalyzer.isMobile() ? '70px' : '80px'}; /* Width of the button */
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        position: fixed; /* Fixes the position relative to the viewport */
        bottom: 0px; /* Distance from the bottom of the viewport */
        right: 0px; /* Distance from the left of the viewport */

        z-index: 1000; /* Ensures the button stays on top of other elements */
        background-color: #add8e6; /* This is a light blue color */
        border: none;

        /* Gradient for a glossy look */
        background-image: linear-gradient(137.2deg, #925df6 1.15%, #83d5fb 140.99%);

        /* Transition for smooth interaction */
        transition: transform 0.3s;
    }

    .chatbotButton:hover {
        transform: scale(1.1);
        transition: transform 1s;
    }

    .chatbotButton img {
        width: 40px;
        height: 40px;
    }

    .chatbot-icon {
        /* Your icon styling here */
        font-size: 16px;
    }

    @media (max-width: 500px) {
        .chatbotButton {
            display: ${({ $isOpen }) => ($isOpen ? 'none' : '')};
        }
    }
`;

export const MessageBubbleActionStyled = styled.div<{ $isOpen?: boolean; $isMobile: boolean }>`
    /* #SpeechBubble is set as a child of #Rectangle to make positioning
    it easier. #SpeechBubble is given an absolute position so that its 
    position is relative to the #Rectangle (its parent). Its scale is 
    set to zero since it should only appear when the mouse hovers over
    #Rectangle. The 'animation-fill-mode' property is set to 'forwards' 
    so that  the scale value stays at 1 after the animation finishes, 
    otherwise  #SpeechBubble will disappear once the animation finishes. 
    The 'animation-name' property is omitted since it's going to be 
    set by jQuery instead */
    #SpeechBubble {
        position: absolute;
        transform-origin: 100% 100%;
        text-align: center;
        background-color: #5a5a5a;
        color: white;
        border-radius: 10px;
        width: ${({ $isMobile }) => ($isMobile ? 'calc(90vw - 40px - 40px)' : '300px;')};
        display: inline-block;
        height: auto;
        padding: 10px;
        right: ${({ $isMobile }) => ($isMobile ? '70px;' : '80px;')};
        bottom: ${({ $isMobile }) => ($isMobile ? '80px;;' : '90px;')};
        transform: scale(0);
        animation-fill-mode: forwards;
        animation-name: ${({ $isOpen }) => ($isOpen ? 'expand-bounce' : 'shrink')};
        animation-duration: ${({ $isOpen }) => ($isOpen ? '0.25s' : '0.1s')};
    }

    /* We need to add a tail to the speech bubble. This is done using
    the ::before pseudo-element. The tail's size is controlled by 
    its borders. The right and bottom borders are given a transparent
    color so that the tail still retains its shape while giving the
    desired look */
    #SpeechBubble::before {
        content: '';
        display: block;
        width: 0;
        position: absolute;
        bottom: -25px;
        right: 5px;
        border-style: solid;
        border-width: 15px;
        border-color: #5a5a5a transparent transparent #5a5a5a;
        transform: rotate(80deg);
    }

    .userProfileImage {
        width: ${deviceAnalyzer.isMobile() ? '20px' : '25px'};
        height: ${deviceAnalyzer.isMobile() ? '20px' : '25px'};
        border-radius: 50%;
        object-fit: cover; /* Ensures the image covers the container */
        display: flex;
    }

    .userIntroduction {
        font-size: 13px;
    }

    /* Here the scale changes from 0 to 1. To add the bounciness 
    effect, the scale is set to 1.25 midway through the animation
    to give the animation a nicer effect. */
    @keyframes expand-bounce {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.25);
        }
        100% {
            transform: scale(1);
        }
    }

    /* Here the scale simply goes from 1 back to 0 */
    @keyframes shrink {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
`;
