import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { ClientFactory } from '../client/ClientFactory';
import { ActionType, ShowChatWindowAction } from '../model/ActionTypes';

interface ShowChatWindowContextType {
    showChatWindow: boolean;
    toggleChatWindow: () => void;
    resetChatWindow: () => void;
}

const defaultShowChatWindowContextType: ShowChatWindowContextType = {
    showChatWindow: false,
    toggleChatWindow: () => {},
    resetChatWindow: () => {},
};

export const ShowChatWindowContext = createContext<ShowChatWindowContextType>(defaultShowChatWindowContextType);

export function ShowContextProvider({ children }: { children: ReactNode }) {
    const urlParameterReader = ClientFactory.createURLParameterReader();
    const [showChatWindow, setShowChatWindow] = useState<boolean>(urlParameterReader.retrieveAppParams().initialShow);
    const currentShowChatWindow = useRef(showChatWindow);

    useEffect(() => {
        currentShowChatWindow.current = showChatWindow;
    }, [showChatWindow]);

    const toggleChatWindow = () => {
        setShowChatWindow((prev) => !prev);
    };

    const resetChatWindow = () => {
        if(!currentShowChatWindow.current) {
            ClientFactory.createActionMessageClient().sendActionMessageToAgent({
                actionType: ActionType.ShowChatWindow,
                payload: showChatWindow
            } as ShowChatWindowAction);
        }
    };

    return (
        <ShowChatWindowContext.Provider value={{ showChatWindow, toggleChatWindow, resetChatWindow }}>
            {children}
        </ShowChatWindowContext.Provider>
    );
}

export function useShowChatWindowContext() {
    return useContext(ShowChatWindowContext);
}
