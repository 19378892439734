import styled from 'styled-components';

export const BottomInfoStyled = styled.p`
    color: rgba(48, 52, 55, 1);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.06em;
    text-align: left;
`;

export const InputBoxStyled = styled.div`
    border: 1.5px solid rgba(232, 232, 232, 1);
    border-radius: 10px;
    height: 100px;
    margin: 10px 0px;
`;

export const SendBoxStyled = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0px 16px;

    .send-icon {
        fill: #9389ff;
        transition: fill 0.5s ease-in;
    }

    .disable {
        fill: gray;
    }

    .send-icon:hover {
        fill: #7745ff;
        transition: fill 0.5s ease-in-out;
        cursor: pointer;
    }
`;

export const BottomStyled = styled.div`
    display: flex;
    justify-content: space-evenly;
    padding: 0px 24px 0px 24px;
`;

export const BottomTermsStyled = styled.a`
    color: rgb(122, 75, 255);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.06em;
    text-align: left;
`;

export const ChatInputTextarea = styled.div`
    .textarea {
        border: 0px;
        background-color: transparent;
        height: 16px;
        resize: none;
    }
    .textarea:focus {
        outline: none !important;
        box-shadow: none !important;
    }
`;

export const ChatInputStyled = styled.div`
    .AIEveywhereChatInput {
        position: fixed;
        bottom: calc(2.5rem); /* parent's bottom */
        right: calc(2rem + 1rem); /* parent's right + parent's padding */
        width: 21rem; /* parent's width - horizontal paddings */
        background-color: #fff;
        box-sizing: border-box;
    }
    .AIEverywhereChatButton {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        align-items: flex-end;
    }
    .AIEverywhereCopyright {
        font-size: 0.8em;
        color: gray;
        margin: 0 0 4px;
    }
`;
