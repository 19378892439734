import { ClientFactory } from '../client/ClientFactory';
import { v4 as uuidv4 } from 'uuid';
import { IdentifiersContextType } from '../context/Identifiers.context';
import { URLParamAppParameterReader } from '../client/param/URLParamAppParameterReader';
import { ActionType, SetCustomerIdAction } from '../model/ActionTypes';

const CUSTOMER_ID_KEY = 'customerId';
export function useIdentifier() {
    const paramExtractor: URLParamAppParameterReader = ClientFactory.createURLParameterReader();
    const cookieStorage = ClientFactory.createCookieStorage();
    const facadeServiceClient = ClientFactory.createFacadeServiceClient();

    function loadCustomerId(): string {
        const customerId = cookieStorage.get(CUSTOMER_ID_KEY, uuidv4());
        cookieStorage.save(CUSTOMER_ID_KEY, customerId);

        ClientFactory.createActionMessageClient().sendActionMessageToAgent({
            actionType: ActionType.SetCustomerId,
            payload: customerId
        } as SetCustomerIdAction)
        return customerId;
    }

    async function getApplicationId(serviceId: string): Promise<string> {
        return (await facadeServiceClient.getApplication(serviceId)).id;
    }

    async function loadIdentifiers(): Promise<IdentifiersContextType> {
        const serviceId = (paramExtractor.retrieveAppParams()).serviceId;

        return {
            serviceId: serviceId,
            customerId: loadCustomerId(),
            applicationId: await getApplicationId(serviceId),
            isReady: false,
            isError: false,
        };
    }

    return { loadIdentifiers }
}