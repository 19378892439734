import { Message } from '../model/message/Message';
import { createContext, useContext, useEffect, useState } from 'react';
import { ClientFactory } from '../client/ClientFactory';
import { useCustomer } from '../hooks/useCustomer';
import { CUSTOMER_KEY } from '../model/Conversation';
import { IdentifiersContext } from './Identifiers.context';

interface ChatMessageContextType {
    messages: Message[];
    setMessages: (messages: Message[]) => void;
    pushMessage: (message: Message) => void;
    waitingForResponse: boolean;
    setWaitingForResponse: (waitingForResponse: boolean) => void;
}

export const ChatMessageContext = createContext<ChatMessageContextType>({
    messages: [],
    setMessages: (messages) => {},
    pushMessage: (message) => {},
    waitingForResponse: false,
    setWaitingForResponse: (waitingForResponse) => {},
});

export function ChatMessageContextProvider({ children }: { children: React.ReactNode }) {
    const [messages, setMessages] = useState<Message[]>([]);
    const { serviceId, isReady } = useContext(IdentifiersContext);
    const chatClient = ClientFactory.createChatClient();
    const facadeServiceClient = ClientFactory.createFacadeServiceClient();
    const { loadCustomerInfo } = useCustomer(serviceId);
    const [waitingForResponse, setWaitingForResponse] = useState(false);

    const loadConversation = async () => {
        const customerInfo = await loadCustomerInfo();
        const chatSessionId = `${CUSTOMER_KEY}-${serviceId}`;

        const token = await facadeServiceClient.getGuestToken(chatSessionId);

        await chatClient.connect(chatSessionId, token);
        if (!!customerInfo.latestConversationId) {
            await chatClient.addMessageListener(customerInfo.latestConversationId!, (message) => {
                pushMessage(message);
            });
            await chatClient.addWaitingForResponseListener(customerInfo.latestConversationId!, (waitingForResponse) => {
                setWaitingForResponse(waitingForResponse);
            });

            setMessages(await chatClient.getMessages(customerInfo.latestConversationId!));
        }
    };

    const pushMessage = (message: Message) => {
        setMessages((prevMessages) => [...prevMessages, message]);
    };

    useEffect(() => {
        if (isReady) {
            loadConversation();
        }
    }, [isReady]);

    return <ChatMessageContext.Provider value={{ messages, setMessages, pushMessage, waitingForResponse, setWaitingForResponse }}>{children}</ChatMessageContext.Provider>;
}
