import { useQuery } from '@tanstack/react-query';
import { ClientFactory } from '../../../client/ClientFactory';
import { useContext } from 'react';
import { IdentifiersContext } from '../../../context/Identifiers.context';


export interface ChatRoomHeaderProps {
    title: string;
    greetingMessage: string;
}

export function useChatRoomTitle(): ChatRoomHeaderProps {
    let facadeServiceClient = ClientFactory.createFacadeServiceClient();
    const { serviceId } = useContext(IdentifiersContext);

    async function loadChatRoomHeader(): Promise<ChatRoomHeaderProps> {
        const service = await facadeServiceClient.getServiceByServiceId(serviceId);
        const application = await facadeServiceClient.getApplication(serviceId);

        return {
            title: service.name,
            greetingMessage: application.greetingMessage ?? 'Hello Customer! What can I do for you?',
        }
    }

    const { data = {title: '', greetingMessage: ''} } = useQuery({
        queryKey: ['chatRoomHeader', serviceId],
        queryFn: loadChatRoomHeader
    });

    return data;
}